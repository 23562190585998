<template>
  <div
    class="engineer-create create-form"
    v-if="getPermission('engineer:create')"
  >
    <v-form
      ref="engineerForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <template v-if="updateEngineer">
                    <v-skeleton-loader
                      class="custom-skeleton large-skeleton-title"
                      v-if="pageLoading"
                      type="text"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <h1 class="form-title margin-auto">
                        {{ getEngineerName }}
                      </h1>
                    </template>
                  </template>
                  <template v-else>
                    <h1 class="form-title d-flex margin-auto">
                      Create Engineer
                    </h1>
                  </template>
                </v-col>
                <v-col cols="6" class="pt-0 top-action-buttons d-flex">
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    depressed
                  >
                    Cancel
                  </v-btn>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton large-skeleton-action-button"
                      type="button"
                    >
                    </v-skeleton-loader>
                  </template>
                  <v-btn
                    v-else
                    class="mx-2 custom-bold-button"
                    depressed
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    v-on:click="onSubmit"
                    color="cyan white--text"
                  >
                    <template v-if="updateEngineer">Update Engineer</template>
                    <template v-else>Save Engineer</template>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-tabs
              v-model="engineerTab"
              background-color="transparent"
              centered
              color="cyan"
              fixed-tabs
              class="custom-tab-transparent"
            >
              <v-tab :href="'#personal-info'">
                <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
                Personal Info
              </v-tab>
              <v-tab :href="'#emergency-contact'">
                <v-icon color="grey darken-4" left>mdi-phone</v-icon>
                Emergency Contact
              </v-tab>
              <v-tab :href="'#engineer-address'">
                <v-icon color="grey darken-4" left>mdi-home-map-marker</v-icon>
                Address
              </v-tab>
              <v-tab :href="'#color'">
                <v-icon color="grey darken-4" left
                  >mdi-format-color-fill</v-icon
                >
                Color
              </v-tab>
            </v-tabs>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <v-tabs-items v-model="engineerTab" class="custom-min-height-60vh">
              <v-tab-item :value="'personal-info'">
                <PersonalInfo
                  :pageLoading="pageLoading"
                  :updateEngineer="updateEngineer"
                  :engineer="engineerArr"
                ></PersonalInfo>
              </v-tab-item>
              <v-tab-item :value="'emergency-contact'">
                <EmergencyContact
                  :pageLoading="pageLoading"
                  :updateEngineer="updateEngineer"
                  :engineer="engineerArr"
                ></EmergencyContact>
              </v-tab-item>
              <v-tab-item :value="'engineer-address'">
                <EngineerAddress
                  :pageLoading="pageLoading"
                  :updateEngineer="updateEngineer"
                  :engineer="engineerArr"
                ></EngineerAddress>
              </v-tab-item>
              <v-tab-item :value="'color'">
                <ColorTemplate
                  :pageLoading="pageLoading"
                  :updateEngineer="updateEngineer"
                  :engineer="engineerArr"
                ></ColorTemplate>
              </v-tab-item>
            </v-tabs-items>
            <!-- </perfect-scrollbar> -->
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInfo from "@/view/pages/engineer/create-or-update/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/create-or-update/Emergency-Contact";
import EngineerAddress from "@/view/pages/engineer/create-or-update/Engineer-Address";
import ColorTemplate from "@/view/pages/engineer/create-or-update/Color";
import EngineerMixin from "@/core/lib/engineer/engineer.mixin";

export default {
  mixins: [CommonMixin, EngineerMixin],
  name: "manage-engineer-create",
  title: "Create Engineer",
  components: {
    PersonalInfo,
    EmergencyContact,
    EngineerAddress,
    ColorTemplate
  },
  watch: {
    engineer(param) {
      if (param > 0) {
        this.getEngineerDetails();
      }
    }
  },
  methods: {
    getEngineerDetails() {
      const _this = this;
      if (_this.engineer) {
        _this
          .getEngineer()
          .then(response => {
            _this.engineerArr = response;
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Engineer", route: "engineer" },
              { title: "Update" },
              { title: response.barcode }
            ]);
          })
          .catch(error => {
            _this.logError(error);
            _this.$router.go(-1);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Engineer", route: "engineer" },
      { title: "Create" }
    ]);

    _this.getEngineerDetails();
  },
  computed: {
    getEngineerName() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineerArr) === false) {
        return _this.engineerArr.full_name;
      }
      return null;
    }
  },
  created() {
    const _this = this;
    if (
      _this.$route.name == "admin.engineer.update" ||
      _this.$route.name == "engineer.engineer.update"
    ) {
      _this.updateEngineer = true;
      _this.pageLoading = true;
      _this.engineer = _this.$route.params.id;
      if (!_this.engineer || _this.engineer <= 0) {
        _this.$router.go(-1);
      }
    }
  }
};
</script>
