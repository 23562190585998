<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="8" offset-md="2">
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template>
              <v-hover v-slot="{ hover }">
                <div class="custom-google-autocomplete">
                  <v-text-field
                    id="address-map"
                    v-model.trim="searchAddress"
                    append-icon="search"
                    dense
                    filled
                    label="Search Address"
                    solo
                    flat
                    color="cyan"
                    :loading="autocompleteLoading"
                    v-on:keyup="getGoogleAddress"
                    v-on:blur="isFocused = false"
                    v-on:focus="isFocused = true"
                  >
                  </v-text-field>
                  <v-list
                    v-if="(hover || isFocused) && autocompleteList.length"
                    two-line
                    elevation="4"
                    class="custom-google-autocomplete-list"
                  >
                    <v-list-item
                      v-for="(address, index) in autocompleteList"
                      :key="index"
                      link
                      v-on:click="selectGoogleAddress(index)"
                    >
                      <v-list-item-icon class="m-0 mr-3 my-auto">
                        <v-icon color="cyan"> mdi-map-marker </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content
                        class="py-1"
                        v-if="
                          lodash.isEmpty(address.structured_formatting) ===
                            false
                        "
                      >
                        <v-list-item-title class="font-size-14">{{
                          address.structured_formatting.main_text
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="font-size-12">{{
                          address.structured_formatting.secondary_text
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content class="py-1" v-else>
                        <v-list-item-title class="font-size-14">{{
                          address.description
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-hover>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="address.street_1"
              :rules="[
                validateRules.required(address.street_1, 'Street 1'),
                validateRules.minLength(address.street_1, 'Street 1', 1),
                validateRules.maxLength(address.street_1, 'Street 1', 255)
              ]"
              dense
              filled
              label="Street 1"
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="address.street_2"
              :rules="[
                validateRules.minLength(address.street_2, 'Street 2', 1),
                validateRules.maxLength(address.street_2, 'Street 2', 255)
              ]"
              dense
              filled
              label="Street 2"
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="address.unit_no"
              :rules="[
                validateRules.minLength(address.unit_no, 'Unit no.', 1),
                validateRules.maxLength(address.unit_no, 'Unit no.', 255)
              ]"
              dense
              filled
              label="Unit no."
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="address.postal_code"
              :rules="[
                validateRules.required(address.postal_code, 'Postal Code'),
                validateRules.minLength(address.postal_code, 'Postal Code', 1),
                validateRules.maxLength(address.postal_code, 'Postal Code', 255)
              ]"
              dense
              filled
              v-mask="'############'"
              label="Postal Code"
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "Engineer",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false
    },
    updateEngineer: {
      type: Boolean,
      default: false
    },
    engineer: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      timeoutPreLimit: 500,
      timeoutPre: null,
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      autocompleteList: new Array(),
      searchAddress: null,
      address: {
        street_1: null,
        street_2: null,
        unit_no: null,
        postal_code: null
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }
      ]
    };
  },
  watch: {
    address: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeoutPre);
        _this.timeoutPre = setTimeout(function() {
          EngineerEventBus.$emit("update:address", param);
        }, _this.timeoutPreLimit);
      }
    },
    engineer() {
      this.setEngineerAddress();
    }
  },
  methods: {
    setEngineerAddress() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.address.street_1 = _this.engineer.address_line_1;
        _this.address.street_2 = _this.engineer.address_line_2;
        _this.address.unit_no = _this.engineer.unit_number;
        _this.address.postal_code = _this.engineer.postal_code;
      }
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id }
          })
          .then(response => {
            _this.formatAddress(response.data);
          })
          .catch(error => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function() {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress }
              })
              .then(response => {
                _this.autocompleteList = response.data;
              })
              .catch(error => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.address.street_1 = null;
      _this.address.street_2 = null;
      _this.address.unit_no = null;
      _this.address.postal_code = null;
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then(result => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.address.street_1 = result.street_1;
              _this.address.street_2 = result.street_2;
              _this.address.unit_no = result.unit_no;
              _this.address.postal_code = result.zip_code;
            }
          })
          .catch(error => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    }
  },
  mounted() {
    const _this = this;
    _this.setEngineerAddress();
  }
};
</script>
