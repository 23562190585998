<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="8" offset-md="2">
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="emergencyContact.name"
              :rules="[
                validateRules.required(emergencyContact.name, 'Full name'),
                validateRules.minLength(emergencyContact.name, 'Full name', 1),
                validateRules.maxLength(emergencyContact.name, 'Full name', 255)
              ]"
              dense
              filled
              label="Full name"
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="emergencyContact.mobile"
              v-mask="currentPhoneMask"
              :rules="[
                validateRules.required(
                  emergencyContact.mobile,
                  'Mobile number'
                ),
                validateRules.minLength(
                  emergencyContact.mobile,
                  'Mobile number',
                  1
                ),
                validateRules.maxLength(
                  emergencyContact.mobile,
                  'Mobile number',
                  255
                )
              ]"
              dense
              filled
              label="Mobile number"
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="emergencyContact.relation"
              :rules="[
                validateRules.minLength(
                  emergencyContact.relation,
                  'Relation',
                  1
                ),
                validateRules.maxLength(
                  emergencyContact.relation,
                  'Relation',
                  255
                )
              ]"
              dense
              filled
              label="Relation"
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="emergencyContact.address"
              :rules="[
                validateRules.minLength(
                  emergencyContact.address,
                  'Relation',
                  1
                ),
                validateRules.maxLength(
                  emergencyContact.address,
                  'Address',
                  255
                )
              ]"
              dense
              filled
              label="Address"
              solo
              flat
            ></v-text-field>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <v-text-field
              v-else
              v-model.trim="emergencyContact.email"
              :rules="[
                validateRules.validEmail(emergencyContact.email, 'Email'),
                validateRules.minLength(emergencyContact.email, 'Email', 1),
                validateRules.maxLength(emergencyContact.email, 'Email', 255)
              ]"
              dense
              filled
              label="Email"
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "emergency-contact",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false
    },
    updateEngineer: {
      type: Boolean,
      default: false
    },
    engineer: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      emergencyContact: {
        name: null,
        mobile: null,
        relation: null,
        address: null,
        email: null
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }
      ]
    };
  },
  watch: {
    emergencyContact: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function() {
          EngineerEventBus.$emit("update:emergency-contact", param);
        }, _this.timeoutLimit);
      }
    },
    engineer() {
      this.setEmergencyContact();
    }
  },
  methods: {
    setEmergencyContact() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.emergencyContact.name = _this.engineer.emergency_contact_name;
        _this.emergencyContact.mobile = _this.engineer.emergency_contact_number;
        _this.emergencyContact.relation =
          _this.engineer.emergency_contact_relation;
        _this.emergencyContact.address =
          _this.engineer.emergency_contact_address;
        _this.emergencyContact.email = _this.engineer.emergency_contact_email;
      }
    }
  },
  mounted() {
    this.setEmergencyContact();
  }
};
</script>
