var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-row',[_c('v-col',{attrs:{"md":"8","offset-md":"2"}},[(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.required(_vm.emergencyContact.name, 'Full name'),
              _vm.validateRules.minLength(_vm.emergencyContact.name, 'Full name', 1),
              _vm.validateRules.maxLength(_vm.emergencyContact.name, 'Full name', 255)
            ],"dense":"","filled":"","label":"Full name","solo":"","flat":""},model:{value:(_vm.emergencyContact.name),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.name"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.currentPhoneMask),expression:"currentPhoneMask"}],attrs:{"rules":[
              _vm.validateRules.required(
                _vm.emergencyContact.mobile,
                'Mobile number'
              ),
              _vm.validateRules.minLength(
                _vm.emergencyContact.mobile,
                'Mobile number',
                1
              ),
              _vm.validateRules.maxLength(
                _vm.emergencyContact.mobile,
                'Mobile number',
                255
              )
            ],"dense":"","filled":"","label":"Mobile number","solo":"","flat":""},model:{value:(_vm.emergencyContact.mobile),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.mobile"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.minLength(
                _vm.emergencyContact.relation,
                'Relation',
                1
              ),
              _vm.validateRules.maxLength(
                _vm.emergencyContact.relation,
                'Relation',
                255
              )
            ],"dense":"","filled":"","label":"Relation","solo":"","flat":""},model:{value:(_vm.emergencyContact.relation),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "relation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.relation"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.minLength(
                _vm.emergencyContact.address,
                'Relation',
                1
              ),
              _vm.validateRules.maxLength(
                _vm.emergencyContact.address,
                'Address',
                255
              )
            ],"dense":"","filled":"","label":"Address","solo":"","flat":""},model:{value:(_vm.emergencyContact.address),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.address"}}),(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:_c('v-text-field',{attrs:{"rules":[
              _vm.validateRules.validEmail(_vm.emergencyContact.email, 'Email'),
              _vm.validateRules.minLength(_vm.emergencyContact.email, 'Email', 1),
              _vm.validateRules.maxLength(_vm.emergencyContact.email, 'Email', 255)
            ],"dense":"","filled":"","label":"Email","solo":"","flat":""},model:{value:(_vm.emergencyContact.email),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.email"}})],2)],1)],1),_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }